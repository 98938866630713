import React, { useState, useEffect } from "react"
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './write-review.module.css'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import StarRatingComponent from 'react-star-rating-component';
import Footer from '../components/footer'
import { isAuthenticated, getProfile } from "../utils/auth"

import { useForm } from "react-hook-form";
import * as moment from 'moment';

/* https://medium.com/@chrisfitkin/how-to-get-query-string-parameter-values-in-gatsby-f714161104f */
import queryString from 'query-string'

const WriteReview = (props) => {
  
  const { handleSubmit, register, errors } = useForm();
  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ submitError, setSubmitError ] = useState(false);
  const siteTitle = get(this, 'props.data.site.siteMetadata.title')  
  const companySlug = queryString.parse(props.location.search).company; 
  const [ authorName, setAuthorName ] = useState('');
  const [ authorEmail, setAuthorEmail ] = useState('');
  const [ isAuthorNameAndEmailReadOnly, setIsAuthorNameAndEmailReadyOnly ] = useState(false);
  const [ authorCompany, setAuthorCompany ] = useState('');
  const [ comment, setComment ] = useState('');
  const [ commentTitle, setCommentTitle ] = useState('');
  const [ rating, setRating ] = useState(5);
  
  useEffect(onClientLoad, []);

  function onClientLoad() {
    const isUserAuthenticated = isAuthenticated();

    if(isUserAuthenticated){
      var user = getProfile();

      setAuthorName(user.name);
      setAuthorEmail(user.email);
      setIsAuthorNameAndEmailReadyOnly(true);
    }
  }

  function onStarClick(nextValue, prevValue, name) {
    setRating(nextValue);
  }

  //const [validated, setValidated] = useState(false);

  const onSubmit = values => {
    
        const postData = {
            rating : rating,
            // https://momentjs.com/docs/#/displaying/  
            // format expected: 06-Aug-2020 , English
            postDate : moment(new Date()).locale('en').format('DD-MMM-YYYY'),
            authorName : values.authorName,
            authorEmail : values.authorEmail,
            authorCompany : values.authorCompany,
            companySlug : companySlug,
            comment : values.comment,
            commentTitle : values.commentTitle,
            recommendation : values.recommendation
        };

        var postBody = JSON.stringify(postData);      
        console.log(postBody);

        // Post review to the API for submission.
        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
        fetch(`https://www.confiable.club/.netlify/functions/submit-review`,
          {
            method: 'POST',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            body: postBody 
          })
          .then(response => {
              // check for 200-299 status codes
              if (!response.ok) {
                console.log('Network response was not ok');
                setSubmitError(true)
                return;
              }

              setIsSubmitted(true);
              return response.json();
          }) // parse JSON from request
          .then(resultData => 
          {
              console.log(JSON.stringify(resultData))
            //setStarsCount(resultData.stargazers_count)
          })
          .catch((error) => {
            console.error('Unable to connect due to this error:', error);
            setSubmitError(true);
          })

      };

    return (
      <Layout location={props.location}>
        <Helmet title={siteTitle} />   
        <div class="sub_header_in">
            <div class="container">
              <h1>Testimonio</h1>
            </div>
          </div>  
         <main>
          <div class="container margin_60_35">
            <div class="row">
              
              
              <div class="col-xl-6 col-lg-6 col-md-8">
                <div class="box_account">
                 {!isSubmitted &&
                  <form data-netlify="false" onSubmit={handleSubmit(onSubmit)} >			
                    <div class="form_container">
                      <h5 class="add_bottom_15">Tu Informacion</h5>                       
                      <div class="form-group">
                        <input name="authorName" type="text" class="form-control" readOnly={isAuthorNameAndEmailReadOnly} 
                            placeholder="Nombre*" defaultValue={authorName}                  
                          ref={register({
                            required: "Tu nombre es requerido.", 
                            min: 3, max: 100, message : "Tu nombre debe ser valido."                          
                          })}
                          />
                          {errors.authorName && <span class="error_message">{errors.authorName.message}</span>}
                      </div>
                                    
                      <div class="form-group">
                          <input name="authorEmail" type="email" class="form-control" readOnly={isAuthorNameAndEmailReadOnly} 
                              placeholder="Email*" defaultValue={authorEmail}
                          ref={register({
                            required: "Tu correo es requerido para verificar tu identidad.",  
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Tu correo debe ser válido."
                            }                      
                          })}
                          />
                          {errors.authorEmail && <span class="error_message">{errors.authorEmail.message}</span>}
                      </div>

                      <div class="form-group">
                        <input name="authorCompany" type="text" class="form-control" placeholder="Empresa" defaultValue={authorCompany}                  
                          ref={register({                            
                            min: 3, max: 100, message : "Tu empresa debe ser valida."                       
                          })}
                          />
                          {errors.authorCompany && <span class="error_message">{errors.authorCompany.message}</span>}
                      </div>
                      <h5 class="add_top_60 add_bottom_15">Tu Testimonio</h5> 

                      <div class="form-group">
                          <input name="companySlug" class="form-control" plaintext readOnly defaultValue={companySlug} />
                      </div>

                      <div class="form-group add_top_15">
                      <p>¿Cual sería tu retroalimentación a esta empresa?</p>

                        <input name="commentTitle" type="text" class="form-control" placeholder="Titulo*" defaultValue={commentTitle}                  
                          ref={register({
                            required: "Un titulo es requerido.", 
                            min: 3, max: 100, message : "El titulo debe ser valido."                          
                          })}
                          />
                          {errors.commentTitle && <span class="error_message">{errors.commentTitle.message}</span>}
                      </div>

                      <div class="form-group">
                        <textarea name="comment" class="form-control" style={{ height: "200px "}} placeholder="Comentario*" defaultValue={comment}                  
                          ref={register({
                            required: "Tu comentario es requerido.", 
                            min: 3, max: 1000, message : "El comentario debe ser valido."                          
                          })}
                          />
                          {errors.comment && <span class="error_message">{errors.comment.message}</span>}
                      </div>

                      <div class="form-group add_top_15">
                        <p>¿Cual seria la califacción global de esta empresa?</p>
                        <StarRatingComponent 
                          name="rate1"
                          starCount={5}
                          value={rating}
                          onStarClick={onStarClick.bind(this)}
                        /> 
                      </div>

                      <div class="form-group add_top_15">     
                        <p>¿Recomendarías esta empresa a un amigo?</p>

                        <label class="container_radio" >Si recomendaría esta empresa a un amigo.
                          <input type="radio" name="recommendation" value="Yes" ref={register({ required: true })} />
                          <span class="checkmark"></span>
                        </label>
                        <label class="container_radio" >No recomendaría esta empresa a un amigo.
                          <input type="radio" name="recommendation" value="No" ref={register({ required: true })} />
                          <span class="checkmark"></span>
                        </label>
                        {errors.recommendation && <span class="error_message">Porfavor seleccione una opción.</span>}
                      </div>

                      <div class="text-center add_top_60">
                        <input type="submit" value="Enviar Testimonio" class="btn_1 full-width"/>
                      </div>
                    </div>
                  </form>} 
                  {isSubmitted &&
                    <div style={{ "padding" : "20px" }}>
                      <h4>Gracias por tu testimonio</h4>
                      <p>Gracias por tu testimonio, por hacerte escuchar y compartir con otros tu experiencia.</p>
                    </div>
                  }
                </div>
              </div>
                

              <div class="col-xl-6 col-lg-6 col-md-8">
				<div class="box_account">
          <h4 class="add_bottom_15 add_top_15">Tips para escribir un testimonio</h4>
          <p>
            Escribir un buen testimonio es díficil, pero tomarse el tiempo para hacerlo bien será de más ayuda a la empresa
            y a otros futuros clientes. !Recuerda que escribir un testimonio es un ganar-ganar para seguir creciendo y hacer un mejor mundo digital!
          </p>

				
				<div class="row hidden_tablet add_top_30">
					<div class="col-md-12">
						<ul class="list_ok">
							<li>Se detallado, especifico, y honesto.</li>
							<li>Mantenlo profesional y amigable.</li>
							<li>Provee retroalimentación útil y positiva.</li>
              <li>Describe elementos como servicio al cliente, calidad del servicio, y ética profesional.</li>
              <li>Deja tu información correcta para verificar la autenticidad de tu persona.</li>
              <li>Asegurate de dar tu testimonio a la empresa correcta.</li>
						</ul>
					</div>					
				</div>
		
			</div>           
            </div>
          </div></div>
          </main>             
      </Layout>
    )
}

export default WriteReview
